@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    font-family: 'Montserrat', sans-serif;
}

.bg-for-picture {
    filter: drop-shadow(0 4px 30px rgba(0, 0, 0, 0.25));
}

.linear1 {
    background: linear-gradient(180deg, #000C78 0%, #000A62 32.29%, #FFF 32.3%, #FFF 68.23%, #FFF 68.24%, #F00 68.25%);
}

.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: var(--swiper-pagination-color, #000C78) !important;
}

.swiper-container {
    width: 100vw;
}

#navBarHeader {
    animation: navAppear 600ms forwards;
    animation-delay: 1500ms;
    will-change: transform, opacity;
    opacity: 0;
}

@keyframes navAppear {
    0% {
        transform: translate3d(0, -80%, 0);
        opacity: 0;
    }

    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

#titleHeader {
    animation: letterAppear 600ms forwards;
    animation-delay: 1500ms;
    will-change: transform, opacity;
    display: inline-block;
    opacity: 0;
}

@keyframes letterAppear {
    0% {
        transform: translate3d(0, 80%, 0);
        opacity: 0;
    }

    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

#title {
    animation: titleAppear 600ms forwards;
    animation-delay: 1500ms;
    will-change: transform, opacity;
    display: inline-block;
    opacity: 0;
}

@keyframes titleAppear {
    0% {
        transform: translate3d(-5%, 0, 0);
        opacity: 0;
    }

    100% {
        transform: translate3d(0, 0, 0);
        opacity: 1;
    }
}

#subtitle {
    animation: fadeIn 1000ms forwards;
    animation-delay: 1800ms;
    will-change: opacity;
    display: inline-block;
    opacity: 0;
}

.button {
    animation: fadeIn 1000ms forwards;
    animation-delay: 1800ms;
    will-change: opacity;
    display: inline-block;
    opacity: 0;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}